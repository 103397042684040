body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --config-color-one: #768597;
  --config-color-two: rgba(0, 0, 0, 0.08);
}

body {
  background-color: #171923 !important;
}

* {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.fa {
  font-family: FontAwesome !important;
}

p,
span,
pre,
input,
button,
h1,
h2,
h3,
h4,
h5 {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.chakra-popover__arrow {
  box-shadow: none !important;
}

.chakra-menu__menu-list {
  width: fit-content !important;
  max-width: fit-content !important;
}

.d-none {
  display: none !important;
}

#menu-list-menu-lang {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-number::placeholder {
  color: white;
  opacity: 1;
}

#input-number-container-custom::placeholder {
  color: white;
  opacity: 1;
}

.chakra-divider {
  display: block;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
}

*:focus {
  box-shadow: none !important;
}

.width-menu {
  width: 100% !important;
}

.activeNavbar {
  border-bottom-color: #1ac486 !important;
  color: white !important;
}

.activeMenu {
  border-bottom-color: #1ac486 !important;
  color: white !important;
  /* background-color: #2d3748 !important; */
}

.defaultMenu {
  border-color: #1a202c !important;
  color: #718096 !important;
}

.activeSidebar {
  color: #1ac486 !important;
  border-color: #1ac486 !important;
}

.btn-page {
  border-radius: 8px;
  width: 32px !important;
  align-items: center !important;
  height: 32px !important;
  background: var(--chakra-colors-gray-800) !important;
  margin-top: -5px !important;
}

.btn-page:disabled {
  cursor: no-drop;
  color: #718096;
}

.btn-page::after {
  display: none !important;
}

.rc-pagination-item {
  background: var(--chakra-colors-gray-800) !important;
  border-color: none !important;
  border-width: 0px !important;
  color: var(--chakra-colors-gray-500) !important;
  font-size: 16px;
  line-height: 30px !important;
  font-weight: 400;
  min-width: 32px !important;
  width: auto;
  align-items: center !important;
  height: 32px !important;
  border-radius: 8px !important;
  margin: 0px !important;
  margin-right: 5px !important;
}

.rc-pagination-item a {
  color: var(--chakra-colors-gray-500) !important;
  line-height: 32px !important;
}

.pg-small .btn-page {
  width: 28px !important;
  height: 28px !important;
}

.pg-small .rc-pagination-item {
  min-width: 28px !important;
  height: 28px !important;
}

.pg-small .rc-pagination-item a {
  line-height: 28px !important;
}

.rc-pagination-item a:hover {
  color: var(--chakra-colors-gray-500) !important;
}

.rc-pagination-item-active {
  background-color: var(--chakra-colors-gray-700) !important;
  color: var(--chakra-colors-gray-100) !important;
  font-weight: 600 !important;
}

.rc-pagination-item-active a {
  color: var(--chakra-colors-gray-100) !important;
}

.rc-pagination-item-active a:hover {
  color: var(--chakra-colors-gray-100) !important;
}

.rc-pagination-jump-next {
  position: relative;
  margin: 0px !important;
}

.rc-pagination-item-link:hover {
  color: var(--chakra-colors-gray-500) !important;
}

.rc-pagination-item-link {
  align-self: flex-end;
}

.rc-pagination-item-link::after {
  padding-top: 10px;
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 5px !important;
}

.chakra-switch__track {
  background: #1a202c !important;
  display: flex !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  align-items: center !important;
}

.style-button-switch .chakra-switch__track {
  background: #262f3d !important;
  display: flex !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  align-items: center !important;
}

/* .chakra-switch__thumb {
      background: #1AC486 !important;
      
  } */

.chakra-switch__track[data-checked] {
  background: #1ac486 !important;
}

.loading-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(26, 32, 44, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #menu-list-menu-option-time {
    width: inherit !important;
    max-width: inherit !important;
    min-width: inherit !important;
  } */

#menu-optionx:last-child {
  /* width: inherit !important; */
  /* max-width: inherit !important; */
  /* min-width: inherit !important; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.markdown-text a {
  color: #1ac486;
}

.markdown-text p {
  padding-top: 5px;
  padding-bottom: 5px;
}

.teal-text {
  color: #1ac486;
}

.white-text {
  color: #ffffff;
}

.ReactVirtualized__Table__headerRow {
  text-transform: none !important;
}

.styleTable {
  margin-bottom: 20px;
}

.custom-virtualized .ReactVirtualized__Grid {
  height: 600px !important;
}

.ReactVirtualized__Grid::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

.ReactVirtualized__Grid::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  border-radius: 24px;
  background-color: #1a202c !important;
}

.ReactVirtualized__Grid::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border: 2px solid #4a5568;
  border-radius: 24px;
}

.style-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container-dashboard {
  background: linear-gradient(180deg, rgba(23, 25, 35, 0) 0%, rgba(26, 196, 134, 0.2) 100%);
}

.container-competition-overview {
  background: linear-gradient(360deg, rgba(23, 25, 35, 0) 0%, rgba(26, 196, 134, 0.1) 100%);
}

.sub_text {
  color: #718096;
}

#list-note-warning li::marker {
  content: '•';
  color: #ecc94b;
  display: block;
  width: 16px;
  left: 0px;
}

.small-than-560 {
  display: flex;
}

.text-content {
  word-spacing: 2px;
}

@media (max-width: 560px) and (min-width: 0px) {
  .small-than-560 {
    display: block;
  }
}

@media (max-width: 1024px) and (min-width: 0px) {
  .react-joyride__spotlight {
    height: 60px !important;
    background: transparent !important;
  }
}

@media (max-width: 480px) and (min-width: 0px) {
  .custom-virtualized .ReactVirtualized__Grid {
    height: 440px !important;
  }
}

/* iframe {
  display: none !important;
} */

.drop {
  position: absolute;
  top: 0;
  z-index: 999;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #fff;
}

.animate {
  animation: falling 10.5s infinite ease-in;
}

.light::-webkit-scrollbar {
  display: none;
}

@keyframes falling {
  0% {
    top: -10px;
  }

  10% {
    transform: translateX(-5px);
  }

  20% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  60% {
    transform: translateX(-5px);
  }

  70% {
    transform: translateX(5px);
  }

  80% {
    transform: translateX(-5px);
  }

  90% {
    transform: translateX(5px);
  }

  95% {
    opacity: 1;
  }

  100% {
    top: 97%;
    opacity: 0;
  }
}

.line {
  text-align: center;
}

.light ul li {
  position: relative;
  margin: 0 15px;
  list-style: none;
  padding: 0;
  display: inline-block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  top: 35px;
  background: #fff;
}

.light ul li:before {
  content: '';
  position: absolute;
  background: #222;
  width: 10px;
  height: 9px;
  border-radius: 3px;
  top: -4px;
  left: 1px;
}

.light ul li:after {
  content: '';
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 19px;
  border-bottom: solid #222 2px;
  border-radius: 30%;
}

.red {
  background-color: #fb4545 !important;
  animation: lightningRed 1s infinite;
}

.green {
  background-color: #24d024 !important;
  animation: lightningGreen 0.8s infinite;
}

.yellow {
  background-color: #fff952 !important;
  animation: lightningYellow 0.9s infinite;
}

.blue {
  background-color: #0a53de !important;
  animation: lightningBlue 1.1s infinite;
}

.pink {
  background-color: #f53896 !important;
  animation: lightningPink 1.2s infinite;
}

@keyframes lightningRed {
  0% {
    box-shadow: 5px 10px 35px 10px #fb4545;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 5px 10px 35px 10px #fb4545;
  }
}

@keyframes lightningGreen {
  0% {
    box-shadow: 5px 0 35px 10px #24d024;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 5px 0 35px 10px #24d024;
  }
}

@keyframes lightningYellow {
  0% {
    box-shadow: 5px 0 35px 10px #fff952;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 5px 0 35px 10px #fff952;
  }
}

@keyframes lightningBlue {
  0% {
    box-shadow: 5px 0 35px 10px #0a53de;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 5px 0 35px 10px #0a53de;
  }
}

@keyframes lightningPink {
  0% {
    box-shadow: 5px 0 35px 10px #f53896;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 5px 0 35px 10px #f53896;
  }
}

@media (max-width: 1024px) {
  .line {
    padding: 0;
  }

  .light ul li {
    margin: 0 15px 20px 15px;
  }
}

/* .line:last-child li{
    display:none !important;
  } */

.card .content-card {
  height: 100%;
  background-color: #1a202c;
}

.card {
  padding: 3px;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0px 0px 12px #74eadf;
}

.card::before {
  content: '';
  position: absolute;
  width: 240%;
  height: 220%;
  background: repeating-linear-gradient(white 0%,
      white 7.5px,
      #018a2a 7.5px,
      #018a2a 15px,
      white 15px,
      white 22.5px,
      #018a2a 22.5px,
      #018a2a 30px);
  transform: translateX(-20%) translateY(-20%) rotate(-45deg);
}

.card .content-card {
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
}

.hat-noel {
  position: absolute;
  top: -20px;
  right: 0px;
}

.textDisabled {
  color: white !important;
}

.blink {
  animation: blinker 1s infinite;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.menuLink {
  text-decoration: none !important;
}

.style-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.css-scroll::-webkit-scrollbar-track {
  background-color: #2d3748;
}

.css-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #718096;
}

.css-scroll::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.css-scroll::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

.divider-style {
  height: 3px;
  width: 100%;
  box-shadow: 0px 0px 28px 3px #1ac486;
  /* 4px solid #1AC486 */
}

.arrow-child-animation {
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -ms-transition: transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
  display: none !important;
  overflow: hidden;
  height: 0;
}

.parent-modal-balance-row:hover .arrow-child-animation {
  display: flex !important;
  height: 16px;
  width: 100px;
  position: relative;
  animation-name: animation;
  animation-duration: 4s;
}

@keyframes animation {
  0% {
    margin-left: -10px;
    top: 0px;
  }

  25% {
    margin-left: 10px;
    top: 0px;
  }
}

.parent-modal-balance-row:hover .arrow-child {
  display: flex !important;
  height: 20px;
}

.border-gradient-referral {
  position: relative;
  box-shadow: 0 -5px 10px -5px #1ac486;
  /* box-shadow: #1ac486 0px 0px 2px 2px, #1ac486 2px -2px 4px 1px, #1ac486 0px 0px 2px 0px inset; */
}

.border-gradient-referral::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  padding: 3px;
  padding-bottom: 0px;
  background: linear-gradient(180deg, #1ac486 0%, rgba(7, 126, 83, 0) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.lottery-card {
  position: relative;
}

.lottery-card::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  background: linear-gradient(90deg, rgba(22, 83, 96, 0) 0%, #165360 50.51%, rgba(22, 83, 96, 0) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.border-button-gradient {
  box-shadow: 0px 3px 10px 8px #00a267;
}

.slide-in.from-right .slide-in-content {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in.show .slide-in-content {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.border-gradient {
  position: relative;
  box-shadow: 0 -5px 10px -5px #1ac486;
  /* box-shadow: #1ac486 0px 0px 2px 2px, #1ac486 2px -2px 4px 1px, #1ac486 0px 0px 2px 0px inset; */
}

.border-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  padding: 3px;
  padding-bottom: 0px;
  background: linear-gradient(180deg, #1ac486 0%, #1d232e 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.description-video-animate {
  animation: fadeIn 1.5s;
}

.item-detail {
  background: linear-gradient(180deg, rgba(0, 255, 209, 0.32) -66.67%, rgba(45, 55, 72, 0.55) 100%);
  border-radius: 24px;
  border: solid 3px #1ac486;
  position: relative;
  box-shadow: 0px 0px 10px #1ac486;
  animation: animateLeftToRight 1.5s;
  -webkit-animation: 1.5s animateLeftToRight;
  cursor: pointer;
  text-decoration: none !important;
}

.item-detail-mobile {
  background: linear-gradient(180deg, rgba(0, 255, 209, 0.32) -66.67%, rgba(45, 55, 72, 0.55) 100%);
  border-radius: 24px;
  border: solid 3px #1ac486;
  position: relative;
  box-shadow: 0px 0px 10px #1ac486;
  cursor: pointer;
  text-decoration: none !important;
}

.item-detail-mobile:hover {
  box-shadow: none;
  border-width: 3px !important;
  border-color: transparent !important;
  border-left-color: #1ac486 !important;
  border-right-color: #6994e7 !important;
  background: linear-gradient(94.09deg, #1ac486 0%, #6994e7 99.23%);
  text-decoration: none !important;
}

.item-detail:hover {
  box-shadow: none;
  border-width: 3px !important;
  border-color: transparent !important;
  border-left-color: #1ac486 !important;
  border-right-color: #6994e7 !important;
  background: linear-gradient(94.09deg, #1ac486 0%, #6994e7 99.23%);
  text-decoration: none !important;
}

.item-detail-right {
  background: linear-gradient(180deg, rgba(0, 255, 209, 0.32) -66.67%, rgba(45, 55, 72, 0.55) 100%);
  border-radius: 24px;
  border: solid 3px #1ac486;
  position: relative;
  box-shadow: 0px 0px 10px #1ac486;
  animation: animateRightToLeft 1.5s;
  -webkit-animation: 1.5s animateRightToLeft;
  cursor: pointer;
  text-decoration: none !important;
}

.item-detail-right:hover {
  box-shadow: none;
  border-width: 3px !important;
  border-color: transparent !important;
  border-left-color: #1ac486 !important;
  border-right-color: #6994e7 !important;
  background: linear-gradient(94.09deg, #1ac486 0%, #6994e7 99.23%);
  text-decoration: none !important;
}

.item-detail-right:hover .item-child {
  color: white !important;
  text-decoration: none !important;
}

.item-detail:hover .item-child {
  color: white !important;
  text-decoration: none !important;
}

@keyframes animateLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-moz-keyframes animateLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes animateLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-o-keyframes animateLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-ms-keyframes animateLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animateRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-moz-keyframes animateRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes animateRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-o-keyframes animateRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-ms-keyframes animateRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.half-circle {
  border: 2px solid #4a5568;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.table-bond td {
  border-color: #2d3748;
}

.recaptcha div iframe {
  display: block !important;
  margin-bottom: -90px;
}

.header-table-left {
  color: var(--chakra-colors-gray-500);
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}

.header-table-center {
  color: var(--chakra-colors-gray-500);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
}

.header-table-right {
  color: var(--chakra-colors-gray-500);
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  padding-right: 0px;
}

.ReactVirtualized__Table__row:hover {
  background: #2d3748;
  padding-right: '0px !important';
  height: '75px !important';
  padding-top: '8px';
  padding-bottom: '8px';
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid #2d3748;
  border-top: 0px solid #2d3748;
}

.carousel {
  animation-name: carousel;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

#table-list-bonds .ReactVirtualized__Table__row:hover {
  background: #1a212a;
  padding-right: '0px !important';
  height: '75px !important';
  padding-top: '8px';
  padding-bottom: '8px';
}

@keyframes carousel {
  from {
    margin-left: -100px;
    overflow: hidden;
    z-index: -1;
    opacity: 0.1;
  }

  to {
    margin-left: 0px;
  }
}

.box-shawdow-css {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.32);
}

.arrow-child-icon {
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -ms-transition: transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
  display: none !important;
  overflow: hidden;
  height: 0;
}

.hover-icon:hover .arrow-child-icon {
  display: flex !important;
}

.hover-icon:hover .arrow-child-icon-hover {
  display: none !important;
}

.box-shadow-menu {
  box-shadow: 0px -4px 36px rgba(0, 0, 0, 0.32) !important;
}

nav {
  position: sticky;
  top: 0px;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.36) !important;
  color: white;
  transition: transform 150ms ease-in-out;
  z-index: 99;
  background: #1a202c;
  width: 100%;
  align-items: center;
}

nav.nav-bar--hidden {
  transform: translateY(-100%);
}

.hidden-price {
  transform: translateY(-100px);
}

#price-menu {
  position: fixed;
  top: '60px';
  width: 100%;
}

.css-xxkadm[data-checked] {
  background: #1ac486 !important;
  box-shadow: none !important;
  border-color: #1ac486 !important;
}

.css-xxkadm {
  border: 1.5px solid #718096 !important;
}

.css-1aknl1t {
  border: 1.5px solid #718096 !important;
}

.bg-checkbox::before {
  background: #1ac486 !important;
  width: 77% !important;
  height: 75% !important;
  border-radius: 75% !important;
}

.css-1d3gwlu[data-checked] {
  background: #1a202c !important;
  border-color: #718096 !important;
}

.css-kvq5j9[data-checked] {
  background: #1a202c !important;
  border-color: #718096 !important;
  border: 1.5px solid #718096 !important;
}

.noneBoxShadowBtn {
  box-shadow: none !important;
}

.css-xxkadm:focus,
.css-xxkadm[data-focus] {
  box-shadow: none !important;
}

.input_placeholder::placeholder {
  color: #718096 !important;
}

.breadcrumb nav {
  background-color: #171923 !important;
  box-shadow: none !important;
  z-index: 1;
}

.breadcrumb.breadcrumb--bg-inherit nav {
  background-color: inherit !important;
}

.react-joyride__spotlight {
  height: 66px !important;
}

.react-joyride__tooltip {
  padding: 12px !important;
  border-radius: 12px !important;
}

.table-scroll {
  /*width:100%; */
  display: block;
  empty-cells: show;

  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead {
  position: relative;
  display: block;
  width: 100%;
  overflow-y: scroll;
}

.table-scroll tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.table-scroll tr {
  width: 100%;
  display: flex;
}

.table-scroll td,
.table-scroll th {
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 1rem;
}

.body-half-screen {
  max-height: 70vh;
}

.small-col {
  flex-basis: 10%;
}

/* .table-bonds thead, tbody { display: block } */

/* pr={['140px','140px','140px','40px','60px','140px']} */

button.danger {
  background-color: #f56565;
  border: 1px solid #f56565;
  color: white;
}

button.danger:hover {
  background-color: #f56565;
  opacity: 0.7;
}

button.danger:focus {
  background-color: #f56565;
  opacity: 1;
}

button.danger:active {
  background-color: #f56565;
  opacity: 1;
}

button.danger[disabled] {
  background-color: #cbd5e0;
  border: 1px solid #cbd5e0;
  opacity: 0.7;
}

button.danger[disabled]:hover {
  background-color: #cbd5e0;
  border: 1px solid #cbd5e0;
}

button.primary {
  background-color: #1ac486;
  border: 1px solid #1ac486;
  color: white;
}

button.primary:hover {
  background-color: #1ac486;
  opacity: 0.7;
}

button.primary:active {
  background-color: #1ac486;
}

button.primary:focus {
  background-color: #1ac486;
  opacity: 1;
}

#menu-list-menu-lang {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}

button.primary[disabled] {
  background-color: #cbd5e0;
  border: 1px solid #cbd5e0;
  opacity: 0.7;
}

button.primary[disabled]:hover {
  background-color: #cbd5e0;
  border: 1px solid #cbd5e0;
}

#bond-exchange-tabs button[aria-selected='true'] {
  color: #1ac486 !important;
}

.table-bond-exchange tbody {
  display: block;
  height: 357px;
  overflow: auto;
}

.table-bond-exchange thead,
.table-bond-exchange tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* style-scroll-1 */

.css-scroll::-webkit-scrollbar-track {
  background-color: #1a202c;
}

.list-pool::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2d3748;
}

.list-pool::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

.list-pool::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #1a202c;
}

.css-scroll::-webkit-scrollbar-thumb {
  background-color: #2d3748;
  border: 2px solid #2d3748;
}

.css-scroll::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

.css-scroll--light::-webkit-scrollbar-track {
  background-color: white;
}

.css-scroll--light::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border: 2px solid #cbd5e0;
}

.css-scroll--light::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #cbd5e0;
}

.style-scroll::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

/* end-style-scroll-1 */

/* scroll-style-two */

.css-scroll-two::-webkit-scrollbar-track {
  background-color: #2d3748;
}

.css-scroll-two::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #718096;
}

.css-scroll-two::-webkit-scrollbar-thumb {
  background-color: #2d3748;
  border: 2px solid #2d3748;
  border-radius: 20px;
}

.list-pool::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.css-scroll-two::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

/* end-scroll_two */

/* scroll-style-three */

.css-scroll-three::-webkit-scrollbar-track {
  background-color: #1a202c;
}

.css-scroll-three::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.css-scroll-three::-webkit-scrollbar-thumb {
  background-color: #2d3748;
  border: 2px solid #2d3748;
  border-radius: 20px;
}

.css-scroll-three::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

/* end-scroll_three */

.no-scroll::-webkit-scrollbar {
  display: none;
}

.TVChartContainer {
  /* height: 100%; */
  width: 100%;
}

.tradingViewChart {
  /* resize: both; */
  /* overflow: auto;
  min-width: 75%;
  max-width: 100%; */
}

#chart-tabs button[aria-selected='true'] {
  color: #1ac486;
}

#virtual-list-pair .header {
  color: var(--chakra-colors-gray-500);
  font-weight: 500 !important;
  font-size: 14px;
}

#virtual-list-pair .table-cell {
  color: white;
  font-weight: 400 !important;
  font-size: 14px;
}

.switch_trade .chakra-switch__track {
  background: #2d3748 !important;
}

.switch_trade .chakra-switch__track[data-checked] {
  background: #1ac48666 !important;
}

.switch_active .css-bkp8x1 {
  background: #1ac486 !important;
}

.switch_disabled .css-bkp8x1 {
  background: #718096 !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.green-color {
  color: #1ac486 !important;
  font-weight: medium !important;
}

.red-color {
  color: var(--chakra-colors-red-400) !important;
  font-weight: medium !important;
}

.DateRangePicker__MonthHeader {
  color: #fff !important;
}

.DateRangePicker__PaginationArrow:hover {
  background: transparent !important;
}

.DateRangePicker__Weekdays,
.DateRangePicker__WeekdayHeading>abbr {
  color: #1ac486 !important;
  font-weight: 500;
}

.DateRangePicker__WeekdayHeading {
  border: none !important;
}

.DateRangePicker__MonthDates {
  border-spacing: unset;
  background-color: transparent !important;
  border: none !important;
}

.DateRangePicker__Date {
  background-color: transparent !important;
  color: var(--chakra-colors-gray-500);
  border: none !important;
}

.DateRangePicker__Date--is-highlighted {
  color: white !important;
  border: none !important;
  border-radius: 50%;
}

.DateRangePicker__Date--is-selected {
  color: white !important;
}

.DateRangePicker__CalendarHighlight--single {
  background-color: var(--chakra-colors-gray-500) !important;
  color: white !important;
  border-radius: 50%;
  border: none !important;
}

.DateRangePicker__MonthHeaderSelect {
  background: var(--chakra-colors-gray-700) !important;
}

/*.DateRangePicker__CalendarHighlight {*/

/*  background-color: var(--chakra-colors-gray-500) !important;*/

/*  color: white !important;*/

/*  border-radius: 50%;*/

/*  width: 100%;*/

/*  height: 100%;*/

/*  border: none !important;*/

/*}*/

.DateRangePicker__CalendarSelection--is-pending {
  background: var(--chakra-colors-gray-500) !important;
}

.DateRangePicker__CalendarSelection {
  background: var(--chakra-colors-gray-500) !important;
  border: 1px solid var(--chakra-colors-gray-500) !important;
}

.btn-view-icon {
  color: var(--chakra-colors-teal-150);
}

.btn-view-more:hover .btn-view-icon {
  color: white;
}

.bond-pool-item {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-width: 2px;
  border-color: rgba(26, 196, 134, 0.15);
}

.pair-token {
  position: relative;
  overflow: hidden;
}

.pair-token::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 2px;
  background: linear-gradient(-45deg, #1ac486 0%, rgba(7, 126, 83, 0.2) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 1;
  backdrop-filter: blur(12px);
}

.pair-token-content {
  position: absolute;
  z-index: 1;
  top: -25%;
  left: -15%;
  background: radial-gradient(50% 50% at 50% 50%, #1ac486 0%, rgba(26, 196, 134, 0) 100%);
}

.inner-img {
  transition: 0.3s;
}

.card-bond:hover .inner-img {
  transform: scale(1.1);
}

.cursor-pointer {
  cursor: pointer;
}

.table-list-order tbody {
  display: block;
  height: 357px;
}

.table-list-order thead,
.table-list-order tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

img:not([src]) {
  visibility: hidden;
}

#drawer-body-z::-webkit-scrollbar-track {
  background-color: #1a202c;
}

#drawer-body-z::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

#drawer-body-z::-webkit-scrollbar-thumb {
  background-color: #2d3748;
  border: 2px solid #2d3748;
  border-radius: 20px;
}

#drawer-body-z::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

.mr-0 {
  margin-right: 0px !important;
}

.DateRangePicker__Date--today {
  color: #1ac486;
}

.custom-switch .chakra-switch__track {
  align-items: center !important;
  background: rgb(113, 128, 150, 0.5) !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  display: flex !important;
  height: 14px !important;
  padding: 0px !important;
}

.custom-switch .chakra-switch__thumb {
  background: #718096 !important;
  height: 20px !important;
  padding: 0px;
  transform: translateX(-4px);
  width: 20px !important;
}

.custom-switch .chakra-switch__thumb[data-checked] {
  background: #1ac486 !important;
  height: 20px !important;
  padding: 0px;
  width: 20px !important;
}

.custom-switch .chakra-switch__track[data-checked] {
  background: rgba(26, 196, 134, 0.5) !important;
  height: 14px !important;
  padding: 0px !important;
}

.style-input::placeholder {
  font-size: '14px';
  font-weight: 500;
  color: #4a5568;
}

.style-select .Select-control {
  background-color: #2d3748 !important;
  width: 100%;
  justify-items: center;
  line-height: 54px;
  border-radius: 12px;
  border: none;
}

.style-select .Select-input {
  background-color: #2d3748 !important;
  justify-content: center;
  width: 100%;
  border: none;
}

.style-select .Select-menu-outer {
  background-color: #2d3748 !important;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 12px;
}

.style-select .Select.is-open>.Select-control {
  border-radius: 12px;
  overflow: hidden;
}

.style-select .Select-placeholder {
  line-height: 100px;
}

.style-select .Select-multi-value-wrapper {
  height: 100px;
}

.select-nft .Select-placeholder {
  line-height: 100px !important;
}

.select-nft .Select-control {
  background-color: #2d3748 !important;
  width: 100%;
  justify-items: center;
  border-radius: 12px;
  border: none;
}

.select-nft .Select-menu-outer {
  background-color: #2d3748 !important;
  width: 100%;
  padding-left: 0px;
  margin-top: 10px;
  border: none;
  border-radius: 12px;
}

.recipient-switch .chakra-switch__track {
  background: #2d3748 !important;
}

.recipient-switch .chakra-switch__track[data-checked] {
  background: #1ac486 !important;
}

.input-recipient {
  text-align: center;
}

.tooltip-arrow {
  margin-top: -4px !important;
}

.tooltip-arrow::after {
  border-color: transparent transparent var(--chakra-colors-gray-700) transparent !important;
}

.tooltip-arrow::before {
  border-color: transparent transparent var(--chakra-colors-gray-700) transparent !important;
}

.tooltip-arrow-light {
  margin-top: -4px !important;
}

.tooltip-arrow-light::after {
  border-color: transparent transparent var(--chakra-colors-gray-50) transparent !important;
}

.tooltip-arrow-light::before {
  border-color: transparent transparent var(--chakra-colors-gray-50) transparent !important;
}

.style-divider {
  box-sizing: content-box;
  border-image-slice: 1 !important;
}

.carousel .control-dots .dot {
  background: #1ac486 !important;
  width: 10px !important;
  height: 10px !important;
  border-color: transparent transparent var(--chakra-colors-gray-700) transparent !important;
}

.input-custom::placeholder {
  color: #2d3748;
  font-size: 14px;
  font-weight: 400;
}

.listPair-tab-level-2>div {
  border-width: 0px;
}

.listPair-tab-level-2 button {
  font-weight: var(--chakra-fontWeights-medium);
  color: var(--chakra-colors-gray-500);
}

.listPair-tab-level-2 button:active {
  background-color: transparent;
}

.listPair-tab-level-2 button[aria-selected='true'] {
  border-color: var(--chakra-colors-teal-150);
  color: white;
}

#tab-custom-x {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.css-style-scroll::-webkit-scrollbar-track {
  background-color: #2d3748;
}

.css-style-scroll::-webkit-scrollbar {
  height: 2px;
  width: 1px;
  background-color: #718096;
}

.css-style-scroll::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.css-style-scroll::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

.homepage-feature__card--light {
  animation: borderFadeLight 0.5s;
}

.homepage-feature__card--dark {
  animation: borderFadeDark 0.5s;
}

.homepage-feature__image--left {
  animation: imageLeftSlide 0.5s;
}

.homepage-feature__image--right {
  animation: imageRightSlide 0.5s;
}

@keyframes borderFadeLight {
  0% {
    border-color: var(--chakra-colors-gray-200);
  }

  100% {
    border-color: #1ac486;
  }
}

@-moz-keyframes borderFadeLight {
  0% {
    border-color: var(--chakra-colors-gray-200);
  }

  100% {
    border-color: #1ac486;
  }
}

@-webkit-keyframes borderFadeLight {
  0% {
    border-color: var(--chakra-colors-gray-200);
  }

  100% {
    border-color: #1ac486;
  }
}

@-o-keyframes borderFadeLight {
  0% {
    border-color: var(--chakra-colors-gray-200);
  }

  100% {
    border-color: #1ac486;
  }
}

@-ms-keyframes borderFadeLight {
  0% {
    border-color: var(--chakra-colors-gray-200);
  }

  100% {
    border-color: #1ac486;
  }
}

@keyframes borderFadeDark {
  0% {
    border-color: var(--chakra-colors-gray-800);
  }

  100% {
    border-color: #1ac486;
  }
}

@-moz-keyframes borderFadeDark {
  0% {
    border-color: var(--chakra-colors-gray-800);
  }

  100% {
    border-color: #1ac486;
  }
}

@-webkit-keyframes borderFadeDark {
  0% {
    border-color: var(--chakra-colors-gray-800);
  }

  100% {
    border-color: #1ac486;
  }
}

@-o-keyframes borderFadeDark {
  0% {
    border-color: var(--chakra-colors-gray-800);
  }

  100% {
    border-color: #1ac486;
  }
}

@-ms-keyframes borderFadeDark {
  0% {
    border-color: var(--chakra-colors-gray-800);
  }

  100% {
    border-color: #1ac486;
  }
}

@keyframes imageLeftSlide {
  0% {
    visibility: hidden;
    transform: translateX(-15px);
  }

  100% {
    visibility: visible;
  }
}

@-moz-keyframes imageLeftSlide {
  0% {
    visibility: hidden;
    transform: translateX(-15px);
  }

  100% {
    visibility: visible;
  }
}

@-webkit-keyframes imageLeftSlide {
  0% {
    visibility: hidden;
    transform: translateX(-15px);
  }

  100% {
    visibility: visible;
  }
}

@-o-keyframes imageLeftSlide {
  0% {
    visibility: hidden;
    transform: translateX(-15px);
  }

  100% {
    visibility: visible;
  }
}

@-ms-keyframes imageLeftSlide {
  0% {
    visibility: hidden;
    transform: translateX(-15px);
  }

  100% {
    visibility: visible;
  }
}

@keyframes imageRightSlide {
  0% {
    visibility: hidden;
    transform: translateX(0px);
  }

  100% {
    visibility: visible;
    transform: translateX(-15px);
  }
}

@-moz-keyframes imageRightSlide {
  0% {
    visibility: hidden;
    transform: translateX(0px);
  }

  100% {
    visibility: visible;
    transform: translateX(-15px);
  }
}

@-webkit-keyframes imageRightSlide {
  0% {
    visibility: hidden;
    transform: translateX(0px);
  }

  100% {
    visibility: visible;
    transform: translateX(-15px);
  }
}

@-o-keyframes imageRightSlide {
  0% {
    visibility: hidden;
    transform: translateX(0px);
  }

  100% {
    visibility: visible;
    transform: translateX(-15px);
  }
}

@-ms-keyframes imageRightSlide {
  0% {
    visibility: hidden;
    transform: translateX(0px);
  }

  100% {
    visibility: visible;
    transform: translateX(-15px);
  }
}

.rotate-infinite {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate-infinite {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-infinite {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-infinite {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

.date-picker-custom {
  font-family: 'Poppins';
}

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.react-datepicker__input-container input:hover {
  border-color: var(--chakra-colors-gray-600) !important;
}

.react-datepicker__input-container input:focus {
  border-color: var(--chakra-colors-teal-150) !important;
  box-shadow: none !important;
}

.react-datepicker__input-container input:active {
  border-color: var(--chakra-colors-teal-150) !important;
  box-shadow: none !important;
}

.react-datepicker__input-container input::placeholder {
  color: var(--chakra-colors-gray-600) !important;
}

.react-datepicker__input-container .input-date-custom:focus {
  border-color: var(--chakra-colors-teal-150) !important;
  box-shadow: none !important;
}

.react-datepicker__input-container .input-date-custom:active {
  border-color: var(--chakra-colors-teal-150) !important;
  box-shadow: none !important;
}

.react-datepicker__input-container input:active {
  box-shadow: none !important;
}

.react-datepicker__input-container input:focus-visible {
  border-color: var(--chakra-colors-teal-150) !important;
  box-shadow: none !important;
  outline: none !important;
}

.date-picker-custom--bond .react-datepicker__input-container input {
  background: var(--chakra-colors-gray-800);
  height: 48px;
}

.date-picker-custom--bond .react-datepicker__input-container input:disabled {
  background: var(--chakra-colors-gray-900);
  height: 48px;
}

.date-picker-custom .react-datepicker {
  background-color: var(--chakra-colors-gray-700);
  border-color: var(--chakra-colors-gray-700);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.date-picker-custom .react-datepicker__triangle::after {
  border-bottom-color: var(--chakra-colors-gray-700) !important;
}

.date-picker-custom .react-datepicker__triangle::before {
  border-bottom-color: var(--chakra-colors-gray-700) !important;
}

.date-picker-custom .react-datepicker__month {
  margin: 16px;
  margin-top: 8px;
}

.date-picker-custom .react-datepicker__header {
  background-color: var(--chakra-colors-gray-700);
  border-color: var(--chakra-colors-gray-700);
  border-radius: 16px;
  margin-top: 14px;
  margin-left: 16px;
  margin-right: 16px;
}

.date-picker-custom .react-datepicker__navigation {
  top: 16px;
}

.date-picker-custom .react-datepicker__day-name {
  width: 30px !important;
  color: var(--chakra-colors-gray-500) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.date-picker-custom .react-datepicker__day {
  width: 30px !important;
  color: white;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-radius: 50%;
  align-items: center;
  line-height: 28px;
}

.date-picker-custom .react-datepicker__day:hover {
  color: var(--chakra-colors-teal-150);
  background: rgba(26, 196, 134, 0.15);
  border-radius: 50%;
}

.date-picker-custom .react-datepicker__day--outside-month {
  color: var(--chakra-colors-gray-600);
}

.date-picker-custom .react-datepicker__day--selected {
  color: white;
  background: var(--chakra-colors-teal-150);
  border-radius: 50%;
}

.date-picker-custom .react-datepicker__day--keyboard-selected {
  color: white;
  background: transparent !important;
  border-radius: 50%;
}

.date-picker-custom .react-datepicker__day--disabled {
  color: var(--chakra-colors-gray-600) !important;
  cursor: no-drop;
}

.time-picker-custom .react-datepicker__triangle::after {
  border-bottom-color: var(--chakra-colors-gray-700) !important;
  left: -40px !important;
}

.time-picker-custom .react-datepicker__triangle::before {
  border-bottom-color: var(--chakra-colors-gray-700) !important;
  left: -40px !important;
}

.time-picker-custom .react-datepicker__header {
  display: none !important;
}

.time-picker-custom .react-datepicker,
.time-picker-custom .react-datepicker__time-container,
.time-picker-custom .react-datepicker__time,
.time-picker-custom .react-datepicker__time-box {
  border-radius: 4px;
}

.time-picker-custom .react-datepicker__time,
.time-picker-custom .react-datepicker__time-list,
.time-picker-custom .react-datepicker__time-box {
  background: var(--chakra-colors-gray-700) !important;
  color: var(--chakra-colors-gray-500) !important;
}

.date-picker-custom--bond .react-datepicker__time-container {
  border-color: var(--chakra-colors-gray-500) !important;
  color: white !important;
}

.date-picker-custom--bond .react-datepicker-time__header {
  color: white !important;
}

.date-picker-custom--bond .react-datepicker__time-list-item {
  background: var(--chakra-colors-gray-700) !important;
}

.date-picker-custom--bond .react-datepicker__time-list-item:hover {
  color: var(--chakra-colors-teal-150);
  background: rgba(26, 196, 134, 0.15);
}

.date-picker-custom--bond .react-datepicker__input-container input {
  color: var(--chakra-colors-gray-100);
  font-weight: 400;
  font-size: 14px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2d3748;
}

.react-datepicker__time-list::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

.react-datepicker__time-list::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #1a202c;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.time-picker-custom .react-datepicker__time-list-item:hover {
  background-color: var(--chakra-colors-gray-600) !important;
}

.time-picker-custom .react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-teal-150) !important;
}

.date-picker-custom .react-datepicker__day--highlighted {
  background: rgba(26, 196, 134, 0.15) !important;
  color: var(--chakra-colors-teal-150) !important;
}

.red-border {
  border-color: var(--chakra-colors-red-400) !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.markdown-custom-to-default h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown-custom-to-default>ol,
.markdown-custom-to-default>ul {
  margin-left: 32px;
}

.markdown-custom-to-default1 ol,
.markdown-custom-to-default1 ul {
  margin-left: 24px;
}

.markdown-custom-to-default a {
  color: var(--chakra-colors-gray-50);
}

.CodeMirror-scroll {
  max-height: 400px;
  font-size: 14px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--chakra-colors-gray-800) !important;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #1a202c;
}

.CodeMirror-vscrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #1a202c;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.CodeMirror-placeholder {
  font-family: Poppins !important;
  opacity: 1 !important;
  font-size: 14px !important;
  color: var(--chakra-colors-gray-600) !important;
}

/* Start Pagination */
/*.rc-pagination-custom--1 .rc-pagination-item, .rc-pagination-custom--1 .rc-pagination-prev, .rc-pagination-custom--1 .rc-pagination-next {*/
/*  background-color: var(--chakra-colors-gray-800) !important;*/
/*  border-radius: 8px !important;*/
/*}*/

/* End Pagination */

.react-datepicker-wrapper:focus {
  box-shadow: none !important;
}

.input-date-custom:focus {
  box-shadow: none !important;
}

.react-datepicker__input-container:focus {
  box-shadow: none !important;
}

.react-datepicker-ignore-onclickoutside {
  box-shadow: none !important;
}

.react-datepicker-ignore-onclickoutside:focus {
  box-shadow: none !important;
}

.input-date-custom::placeholder {
  opacity: 1;
  color: var(--chakra-colors-gray-600) !important;
}

.input-date-custom {
  opacity: 1 !important;
  font-size: 14px !important;
}

/* Start: SwapPage */
.swap-container--dark {
  position: relative;
  background-color: var(--chakra-colors-gray-800);
  border-radius: 16px;
  z-index: 1;
}

.swap-container--dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  padding: 1px;
  background: linear-gradient(180deg, rgba(38, 47, 61, 0) 0%, #262f3d 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.swap-container--light {
  position: relative;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.1);
}

/* End: SwapPage */

#spinning-circle svg {
  width: 200px;
  height: 200px;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-bar--hidden .mb-80 {
  margin-bottom: 80px !important;
}

.color-text-gradient {
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.css-2ksiz1[aria-checked='true'],
.css-2ksiz1[data-checked] {
  background: #1ac486 !important;
  border-color: #1ac486 !important;
}

.css-2ksiz1:focus,
.css-2ksiz1[data-focus] {
  box-shadow: none !important;
}

.css-a1zx1m:focus,
.css-a1zx1m[data-focus] {
  box-shadow: none !important;
}

/* style scroll light  */

.css-scroll-light::-webkit-scrollbar-track {
  background-color: #fff;
}

.css-scroll-light::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f2f5f9;
}

.css-scroll-light::-webkit-scrollbar-thumb {
  background-color: #f2f5f9;
  border: 2px solid #f2f5f9;
}

.css-scroll-light::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #fff;
}

/* end  style scroll light  */

.rotate-180deg {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
}

.table-top-winner tbody {
  display: block;
  height: 312px;
  overflow: auto;
}

.table-list-winner tbody {
  display: block;
  height: 245px;
  overflow: auto;
}

.table-top-winner thead,
.table-top-winner tbody tr,
.scrollable-table thead,
.scrollable-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.color-text-tabs-gradient {
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.color-text-count-down {
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.lottery-number {
  font-family: Montserrat !important;
}

.table-tickets {
  width: 100%;
  overflow-y: auto;
  max-height: 220px;
  min-height: fit-content;
}

.table-tickets th {
  border: 1px solid #0e1f1f;
  text-align: left;
  padding: 12px;
  font-weight: 400;
  font-family: Montserrat !important;
  position: sticky;
  background: #060d0d;
  top: 0;
  z-index: 999;
}

.table-tickets td {
  border: 1px solid #0e1f1f;
  padding: 12px 8px;
  font-weight: 500;
  font-family: Montserrat !important;
}

.table-tickets::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.table-tickets::-webkit-scrollbar-track {
  background-color: #2d3748;
}

.table-tickets::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #718096;
}

.table-tickets::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 2px solid #718096;
}

.table-tickets::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 6px #2d3748;
}

.fill-text-gradient {
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* process bar */

.process-container {
  --barsWh: 14px;
  z-index: 1;
  background-color: white;
  /* Warning for browsers that do not support gradients */
  background: repeating-linear-gradient(-55deg,
      var(--config-color-two) 0px,
      var(--config-color-two) var(--barsWh),
      var(--config-color-one) 0px,
      var(--config-color-one) calc(var(--barsWh) * 2));
}

.process-bar {
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px #0bc5ea;
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  opacity: 0.8;
}

#task-dashboard::after {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  content: url('https://storage.googleapis.com/app.position.exchange/assets/task-dashboard.png') url('https://storage.googleapis.com/app.position.exchange/assets/task-dashboard-1024.png') url('https://storage.googleapis.com/app.position.exchange/assets/task-dashboard-480.png') url('https://storage.googleapis.com/app.position.exchange/assets/social-media.svg') url('https://storage.googleapis.com/app.position.exchange/assets/stake-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/daily-checkin.svg') url('https://storage.googleapis.com/app.position.exchange/assets/spot-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/referral-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/lucky-wheel.svg') url('https://storage.googleapis.com/app.position.exchange/assets/lottery-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/swap-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/futures-task.svg') url('https://storage.googleapis.com/app.position.exchange/assets/nft-task.svg');
}

.process-bar-orange {
  background-color: #ffbc48;
  clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0% 100%);
  background-image: repeating-linear-gradient(-55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.08) 10px,
      rgba(0, 0, 0, 0.08) 20px);
  animation: slide var(--speed) linear infinite;
  will-change: background-position;
}

#table-leader-board-game .ReactVirtualized__Table__headerTruncatedText {
  font-family: Montserrat !important;
}

.ol-style {
  list-style: none;
  counter-reset: item;
  text-align: left;
  font-family: Montserrat !important;
}

.ol-style li {
  margin-bottom: 8px;
  counter-increment: item;
  color: white;
  font-size: 14px;
  font-family: Montserrat !important;
  align-items: stretch !important;
  position: relative;
}

.ol-style li:before {
  margin-right: 10px;
  content: counter(item);
  background: #1a393a;
  border-radius: 100%;
  color: #1ac486;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  position: absolute;
}

.gradient-text {
  background: linear-gradient(270deg, #16f8a5 -53.68%, #8360c3 165.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: Montserrat !important;
}

.md-link {
  text-decoration: underline;
  color: #1ac486;
  font-family: Montserrat !important;
}

.fz-16 {
  font-size: 16px;
  font-family: Montserrat !important;
  font-weight: 600;
}

.fz-14 {
  font-size: 14px;
}

.ml-26 {
  margin-left: 26px;
  font-family: Montserrat !important;
  color: #f2f5f9;
}

.txt-center {
  text-align: center;
  font-family: Montserrat !important;
  margin-bottom: 12px;
}

.treasure-hunt-markdown--title p {
  font-family: Ariom !important;
}

.treasure-hunt-markdown--description p {
  font-family: Montserrat !important;
}

.treasure-hunt-markdown--title a,
.treasure-hunt-markdown--description a {
  background-image: linear-gradient(270deg, #16f8a5 -53.68%, #8360c3 165.26%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-link p a{
  color: #4299E1 !important;
  text-decoration: underline;
}

/* show slider 100% */
.grad {
  border-radius: 4px;
  --barsWh: 14px;
  width: 100%;
  height: 16px;
  z-index: 1;
  background-color: white;
  /* Warning for browsers that do not support gradients */
  background: repeating-linear-gradient(-55deg,
      var(--config-color-two) 0px,
      var(--config-color-two) var(--barsWh),
      var(--config-color-one) 0px,
      var(--config-color-one) calc(var(--barsWh) * 2));
}

.grad-child {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px #0bc5ea;
  background: linear-gradient(270deg, #1ac486 -55.17%, #00c9ff 175.12%);
  opacity: 0.8;
}

/* show slider 0% */
.un-grad {
  border-radius: 4px;
  --barsWh: 14px;
  width: 100%;
  height: 16px;
  z-index: 1;
}

.un-grad-child {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #2d3748;
  background: rgb(255, 255, 255, 0.1);
}

#table-leader-board-game .ReactVirtualized__Table__row {
  border-bottom: 0px !important;
  border-top: 0px !important;
}

#table-leader-board-game .ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid rgba(26, 57, 58, 0.6) !important;
}

#table-list-bonds .ReactVirtualized__Table__row {
  border-color: #1a212a !important;
}

#table-list-bonds .ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid #1a212a !important;
}

#card-intro::after {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  content: url('https://storage.googleapis.com/app.position.exchange/assets/1024bot.svg') url('https://storage.googleapis.com/app.position.exchange/assets/1024top.svg') url('https://storage.googleapis.com/app.position.exchange/assets/1920bot.svg') url('https://storage.googleapis.com/app.position.exchange/assets/1920top.svg') url('https://storage.googleapis.com/app.position.exchange/assets/320bot.svg') url('https://storage.googleapis.com/app.position.exchange/assets/320top.svg') url('https://storage.googleapis.com/app.position.exchange/assets/480bot.svg') url('https://storage.googleapis.com/app.position.exchange/assets/480top.svg') url('https://storage.googleapis.com/app.position.exchange/assets/768bot.svg') url('https://storage.googleapis.com/app.position.exchange/assets/768top.svg') url('https://storage.googleapis.com/app.position.exchange/assets/conqueror-outline.svg') url('https://storage.googleapis.com/app.position.exchange/assets/challenger-outline.svg') url('https://storage.googleapis.com/app.position.exchange/assets/challenger.svg') url('https://storage.googleapis.com/app.position.exchange/assets/conqueror.svg') url('https://storage.googleapis.com/app.position.exchange/assets/dreamer-outline.svg') url('https://storage.googleapis.com/app.position.exchange/assets/dreamer.svg') url('https://storage.googleapis.com/app.position.exchange/assets/dreamer-strange.svg') url('https://storage.googleapis.com/app.position.exchange/assets/conquer-strange.svg');
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {}

.flip-card-back {
  transform: rotateY(180deg);
}

.half-circle-ticket {
  border: 2px solid #1a393a;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.css-style-scroll-hidden::-webkit-scrollbar-track {
  background-color: #2d3748;
}

.css-style-scroll-hidden::-webkit-scrollbar {
  height: 2px;
  width: 0px;
  background-color: #718096;
}

.css-style-scroll-hidden::-webkit-scrollbar-thumb {
  background-color: #718096;
  border: 0px solid #718096;
}

.css-style-scroll-hidden::-webkit-scrollbar-corner {
  -webkit-box-shadow: inset 0 0 0px #2d3748;
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes r1 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.rotate-img img {
  transform: translateY(-50%);
  transform: translateX(-50%);
  animation-name: r1;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.d-inline {
  display: inline-flex;
}

#RFS-StepperContainer {
  padding: 12px;
}

#RFS-StepperContainer button {
  font-weight: 600 !important;
}

#RFS-StepperContainer button.completed {
  background: linear-gradient(270deg, #16f8a5 -53.68%, #8360c3 165.26%) !important;
}

#RFS-StepperContainer button.active {
  background: var(--chakra-colors-gray-600) !important;
}

#RFS-StepperContainer #RFS-Label {
  font-weight: 500 !important;
}

#RFS-StepperContainer #RFS-Connector {
  height: 3px;
  border: none !important;
}

#RFS-StepperContainer #RFS-LabelContainer span {
  max-width: 264px !important;
  margin: 12px 40px;
}

.stepper-step-completed {
  background: linear-gradient(270deg, #16f8a5 -53.68%, #8360c3 165.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.stepper-step-active {
  color: var(--chakra-colors-gray-600) !important;
}

.border-input-left-addon {
  border-radius: 5px !important;
}

.style-placeholder-input ::placeholder {
  color: #768597;
  font-size: 12px;
}


.custom-half-circle {
  border: 2px solid #262F3D;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.custom-checkbox .chakra-checkbox__control {
  border-radius: 4px !important
}

.md-sale-rules h3 {
  margin-top: 12px;
  color: var(--chakra-colors-gray-300);
}

.md-sale-rules ul {
  margin-left: 18px;
  margin-top: 6px;
  color: var(--chakra-colors-gray-500);
  font-size: 14px;
}

/* start: date time picker mobile */
.datepicker--custom {
  position: fixed !important;
  left: 0px !important;
  top: 0px !important;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%)) !important;
  inset: 0px auto auto 0px !important;
}

.datepicker--custom .react-datepicker__time-container {
  border: none;
}

.datepicker--custom .react-datepicker__time-container .react-datepicker__time-box {
  width: 300px !important;
}

.datepicker--custom .react-datepicker__time-container, .datepicker--custom .react-datepicker__time, .datepicker--custom .react-datepicker__month-container {
  width: 100% !important;
}

.datepicker--custom .react-datepicker__time-list {
  overflow-y: hidden !important;
}

.datepicker--custom .react-datepicker__time-box {
  border-bottom-right-radius: 0 !important;
}

.datepicker--custom .react-datepicker__time-list, .datepicker--custom .react-datepicker__time-box {
  height: 56px !important;
}

.datepicker--custom .react-datepicker__time-list {
  display: flex;
  overflow-x: scroll;
}

.datepicker--custom .react-datepicker__triangle::before, .datepicker--custom .react-datepicker__triangle::after {
  display: none;
}

.datepicker--custom .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 56px !important;
}

.datepicker--custom .react-datepicker__time-container .react-datepicker__time {
  background: inherit !important;
}

.datepicker--custom .react-datepicker {
  margin: 8px;
}

.datepicker--custom-hidden {
  display: none !important;
}

/* end: date time picker mobile */

/* start: disable zoom input text ios */
* {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* end: disable zoom input text ios */